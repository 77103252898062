<template>
  <div class="busStopDetail">
    <div
      class="busStopDetail-t"
      v-for="(item, index) in stationInfo"
      :key="index"
    >
      <div class="busStopDetail-t-body" v-if="item.isshow">
        <div class="busStopDetail-tt">
          <div class="routeName">{{ item.routeName }}</div>
        </div>
        <div class="busStopDetail-tb">
          <div class="busStopDetail-tbl">
            <div class="busStopDetail-tblt">
              <div class="name">{{ item.originStation }}</div>
              <div class="img"><img src="./img/right.png" alt="" /></div>
              <div class="name">{{ item.finishStation }}</div>
            </div>
            <div class="busStopDetail-tblb">
              <span>首{{ item.firstDepartureTime }}</span>
              <span>末{{ item.lastDepartureTime }}</span>
            </div>
          </div>
          <div class="busStopDetail-tbr" @click.stop="switchDirection(item)">
            <div class="img"><img src="./img/double.png" alt="" /></div>
            <div class="txt">换向</div>
          </div>
        </div>
        <div class="busStopDetail-info">
          <div class="busStopDetail-info-t">
            <div class="busStopDetail-info-tl">
              <div class="img"><img src="./img/sign.png" alt="" /></div>
              <div class="txt">{{ item.stationName }}</div>
            </div>
            <div class="busStopDetail-info-tr">
              <div class="img"><img src="./img/navigation.png" alt="" /></div>
              <div class="txt">路线</div>
            </div>
          </div>
          <div class="busStopDetail-info-b">
            <div class="item-bc" v-if="item.busState == '即将到站'">
              {{ item.busState }}
            </div>
            <div
              class="item-bc"
              v-else-if="item.busState.indexOf('下一班') != -1"
            >
              {{ item.busState }}
            </div>
            <div
              class="item-bc"
              v-else-if="item.busState.indexOf('前方无车') != -1"
            >
              {{ item.busState }}
            </div>
            <div class="item-bc active" v-else>
              <div
                class="item-bc-c"
                v-for="(oItem, oIndex) in item.busStateList"
                :key="oIndex"
              >
                <div class="item-bcl">
                  距<span>{{ item.stationName }}</span
                  >最近{{ oIndex + 1 }}班：
                </div>
                <div class="item-bcr">
                  还有<span>{{ oItem }}站</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="busStopDetail-c">
      <div class="title">
        <div class="title-l">站点信息</div>
        <div class="title-r" @click="refresh">
          <img src="./img/refresh.png" alt="" />
        </div>
      </div>
    </div>
    <div class="busStopDetail-b">
      <div class="item" v-for="(oItem, oIndex) in lineList" :key="oIndex">
        <div
          :class="[
            'item-t',
            { active: oIndex == 0 || oIndex == lineList.length - 1 },
          ]"
        >
          <img
            :class="[{ active1: !oItem.key }]"
            src="./img/publicBus.png"
            alt=""
            v-if="!oItem.key"
          />
          <img
            src="./img/bus.png"
            alt=""
            v-if="oItem.value.indexOf(stationName) != -1"
          />
        </div>
        <div class="item-b">{{ oItem.value }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { busStopUrl } from "./api.js";
var moment = require("moment");
export default {
  name: "busStopDetail",
  data() {
    return {
      busStop: {},
      busStateList: [],
      stationInfo: [],
      lineInfo: [],
      routeName: "",
      lineType: "",
      lineList: [],
      stationName: "",
    };
  },
  components: {},
  destroyed() {},
  computed: {
    communityId() {
      return this.$store.state.communityId;
    },
    userId() {
      return this.$store.state.userId;
    },
    roomId() {
      return this.$store.state.houseId;
    },
    tenantId() {
      return this.$store.state.tenantId;
    },
    assetId() {
      return this.$store.state.assetId;
    },
  },
  created() {
    this.routeName = this.$route.query.routeName;
    this.lineType = this.$route.query.lineType;
  },
  async mounted() {
    this.getBusStop();
  },
  methods: {
    refresh() {
      this.getBusStop();
    },
    switchDirection(item) {
      item.isshow = !item.isshow;
      this.stationInfo.forEach((oItem) => {
        if (
          item.lineType != oItem.lineType &&
          oItem.routeName == item.routeName
        ) {
          oItem.isshow = !oItem.isshow;
        }

        if (this.routeName == oItem.routeName) {
          if (
            oItem.busState &&
            oItem.busState.indexOf("下一班") == -1 &&
            oItem.busState != "即将到站" &&
            oItem.busState != "前方无车" &&
            oItem.busState.split(",")
          ) {
            oItem.busStateList = oItem.busState.split(",");
            oItem.busStateList.forEach((ele, index) => {
              oItem.busStateList[index] = ele.trim();
              oItem.busStateList[index] = ele.replace(/[^0-9]/gi, "");
            });
            if (
              item.lineType == "forward_line" &&
              oItem.lineType == "reverse_line"
            ) {
              this.busStateList = oItem.busStateList;
            }
            if (
              item.lineType == "reverse_line" &&
              oItem.lineType == "forward_line"
            ) {
              this.busStateList = oItem.busStateList;
            }
          }
        }
      });
      this.lineList = [];
      this.lineInfo.forEach((oItem) => {
        if (this.routeName == oItem.routeName) {
          if (item.lineType == "forward_line" && oItem["forwardLineList"]) {
            this.lineList = JSON.parse(oItem["reverseLineList"]);
          }
          if (item.lineType == "reverse_line" && oItem["reverseLineList"]) {
            this.lineList = JSON.parse(oItem["forwardLineList"]);
          }
          this.lineList.forEach((ele, index) => {
            this.lineList[index] = {
              key: true,
              value: ele,
            };
          });
        }
      });
      let oIndex;
      this.lineList.forEach((ele, index) => {
        if (ele.value.indexOf(this.stationName) != -1) {
          oIndex = index;
        }
      });
      if (this.busStateList.length != 0) {
        this.busStateList.forEach((oEle) => {
          this.lineList[oIndex - oEle].key = false;
        });
      }

      this.$forceUpdate();
    },
    getBusStop() {
      let _this = this;
      this.$axios.get(`${busStopUrl}`).then((res) => {
        if (res.code === 200) {
          this.busStop = res.data;
          this.lineInfo = this.busStop.lineInfo;
          console.log(JSON.parse(this.lineInfo[0].forwardLineList), 3456);
          this.lineInfo.forEach((item) => {
            if (this.routeName == item.routeName) {
              if (this.lineType == "forward_line" && item["forwardLineList"]) {
                this.lineList = JSON.parse(item["forwardLineList"]);
              }
              if (this.lineType == "reverse_line" && item["reverseLineList"]) {
                this.lineList = JSON.parse(item["reverseLineList"]);
              }
              this.lineList.forEach((ele, index) => {
                this.lineList[index] = {
                  key: true,
                  value: ele,
                };
              });
            }
          });
          if (this.busStop.stationInfo) {
            this.stationInfo = this.busStop.stationInfo;
            this.stationInfo.forEach((item) => {
              item.isshow = false;
              if (
                _this.lineType == item.lineType &&
                item.routeName == _this.routeName
              ) {
                item.isshow = true;
                this.stationName = item.stationName;
              }
              if (
                item.busState &&
                item.busState.indexOf("下一班") == -1 &&
                item.busState != "即将到站" &&
                item.busState != "前方无车" &&
                item.busState.split(",")
              ) {
                item.busStateList = item.busState.split(",");
                item.busStateList.forEach((ele, index) => {
                  item.busStateList[index] = ele.trim();
                  item.busStateList[index] = ele.replace(/[^0-9]/gi, "");
                });
                if (
                  _this.lineType == item.lineType &&
                  item.routeName == _this.routeName
                ) {
                  _this.busStateList = item.busStateList;
                }
                let oIndex;
                this.lineList.forEach((ele, index) => {
                  if (ele.value.indexOf(this.stationName) != -1) {
                    oIndex = index;
                  }
                });
                this.busStateList.forEach((oEle) => {
                  this.lineList[oIndex - oEle].key = false;
                });
              }
            });
          }
        } else {
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.busStopDetail {
  background: #f5f5f5;
  min-height: 100vh;
  padding: 43px 26px;
  box-sizing: border-box;
  .busStopDetail-t-body {
    .routeName {
      font-size: 36px;
      font-weight: 700;
      color: #000000;
      line-height: 40px;
      margin-bottom: 27px;
    }
    .busStopDetail-tt {
      padding: 0 24px;
    }
    .busStopDetail-tb {
      display: flex;
      align-items: center;
      padding: 0 24px;
      justify-content: space-between;
      .busStopDetail-tbl {
        .busStopDetail-tblt {
          display: flex;
          align-items: center;
          .name {
            font-size: 26px;
            font-weight: 400;
            color: #000000;
            line-height: 28px;
            max-width: 163px;
          }
          .img {
            margin: 0 10px;
            width: 38px;
            height: 14px;
            font-size: 0;
            img {
              width: 100%;
              height: 100%;
            }
          }
        }
        .busStopDetail-tblb {
          margin-top: 9px;
          font-size: 24px;
          font-weight: 400;
          color: #000000;
          line-height: 40px;
        }
      }
      .busStopDetail-tbr {
        display: flex;
        align-items: center;
        .img {
          width: 36px;
          height: 20px;
          margin-right: 12px;
          font-size: 0;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .txt {
          font-size: 24px;
          font-weight: bold;
          color: #000000;
          line-height: 40px;
        }
      }
    }
    .busStopDetail-info {
      padding: 37px 24px 40px;
      background: #fff;
      margin-top: 33px;
      border-radius: 8px;
      .busStopDetail-info-t {
        display: flex;
        justify-content: space-between;
        .busStopDetail-info-tl {
          display: flex;
          align-items: center;
          .img {
            width: 50px;
            height: 52px;
            margin-right: 24px;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .txt {
            font-size: 32px;
            font-weight: 600;
            color: #000000;
            line-height: 40px;
          }
        }
        .busStopDetail-info-tr {
          text-align: center;
          .img {
            width: 54px;
            height: 54px;
            margin-bottom: 8px;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .txt {
            font-size: 18px;
            font-weight: 400;
            color: #000000;
            line-height: 25px;
          }
        }
      }
      .busStopDetail-info-b {
        padding-top: 57px;
        .item-bc {
          display: flex;
          justify-content: space-between;
          font-size: 28px;
          font-weight: 400;
          color: #000000;
          line-height: 40px;
          .item-bcl,
          .item-bcr {
            span {
              font-weight: 700;
            }
          }
          .item-bcr {
            color: rgba(255, 103, 50, 1);
          }
        }
        .active {
          display: block;
          .item-bc-c {
            display: flex;
            justify-content: space-between;
            margin-bottom: 27px;
          }
        }
      }
    }
  }
  .busStopDetail-c {
    margin: 40px 0;
    padding-left: 30px;
    .title {
      display: flex;
      justify-content: space-between;
      .title-l {
        font-size: 36px;
        font-weight: bold;
        color: #000000;
        line-height: 40px;
      }
      .title-r {
        width: 34px;
        height: 34px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .busStopDetail-b {
    background: #fff;
    overflow-x: auto;
    padding: 17px 41px;
    display: flex;
    align-items: flex-start;
    .item {
      position: relative;
      &::before {
        content: "";
        position: absolute;
        width: 100%;
        height: 8px;
        left: 2px;
        top: 16px;
        background: url("./img/route.png") no-repeat;
        background-size: 100% 100%;
        z-index: 1;
      }
      padding-right: 58px;
      text-align: center;
      &:nth-last-child(1) {
        padding: 0;
      }
      .item-t {
        width: 36px;
        height: 36px;
        margin-bottom: 33px;
        text-align: center;
        font-size: 0;
        img {
          width: 100%;
          height: 100%;
          position: relative;
          z-index: 2;
        }
        .active1 {
          width: 30px;
          height: 26px;
          font-size: 0;
          vertical-align: middle;
        }
        &::after {
          content: "";
          display: inline-block;
          width: 0;
          height: 100%;
          vertical-align: middle;
        }
      }
      .active {
        position: relative;
        position: relative;
        z-index: 2;
        &::before {
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;

          margin: auto;
          content: "";
          width: 21px;
          height: 21px;
          background: #007eff;
          border-radius: 50%;
        }
      }

      &:nth-last-child(1) {
        .active {
          position: relative;
          z-index: 2;
          &::before {
            position: absolute;
            top: 0;
            right: -20px;
            bottom: 0;
            margin: auto;
            content: "";
            width: 21px;
            height: 21px;
            background: #007eff;
            border-radius: 50%;
          }
        }
      }
      .item-b {
        font-size: 28px;
        font-weight: 400;
        color: #999;
        line-height: 40px;
      }
    }
  }
}
</style>
<style lang="less">
.busStopDetail {
}
</style>
